import React, { Fragment } from "react";

const Error404Page = () => (
  <Fragment>
    <section className="h-[calc(100%-410px)] bg-[url('../images/404-page/banner.png')] bg-center bg-cover flex items-center">
      <div className="container text-white">
        <h1 className="text-[120px] font-bold">404</h1>
        <p>The page you are looking for cannot be found.</p>
      </div>
    </section>
  </Fragment>
);

export default Error404Page;
